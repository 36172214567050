import { commonValues } from '@/config/common';
import { PROPERTY_TYPE_DEFAULT, PROPERTY_TYPES } from '@/config/property-types';
import t_SLUGPROPERTYTYPEBNB from '@/config/propertyTypesTrads/t_SLUGPROPERTYTYPEBNB';
import t_SLUGPROPERTYTYPEHOSTELS from '@/config/propertyTypesTrads/t_SLUGPROPERTYTYPEHOSTELS';
import t_SLUGPROPERTYTYPEHOTELS from '@/config/propertyTypesTrads/t_SLUGPROPERTYTYPEHOTELS';
import { useRouteStore } from '@/stores/routes';

export function useRoutes() {
  const nuxtApp = useNuxtApp();
  const {
    locale,
    fallbackLocale,
  } = nuxtApp.$i18n;
  const router = useRouter();
  const { useFormatter } = useFormatters();
  const { useUrl } = useUrls();
  const routeStore = useRouteStore();
  const req = useRequestURL();
  const headers = useRequestHeaders();

  const defaultLanguage = fallbackLocale?.value;
  const default404 = '404';

  const enrichedErrorResponse = (description, url) => {
    return {
      status: 404,
      url: url || req?.href,
      referer: headers?.referer || null,
      userAgent: headers?.['user-agent'] || null,
      error: { message: { description } },
    };
  };

  const actualLanguage = locale?.value || defaultLanguage;
  const actualLangCode = useFormatter.getLocaleObjectByCode(actualLanguage);
  const actualLangIsoCode = useFormatter.getLanguageFromIsoToUnderScore(actualLangCode?.language);

  const getActualLangForUrl = () => {
    return locale?.value === 'en' ? '' : locale?.value;
  };

  const redirectRoute = async (params, query) => {
    const route = router?.currentRoute?.value;
    const urlParts = routeStore.getRoute;

    if (JSON.stringify(params) !== JSON.stringify(urlParts)) {
      let redirectUrl = '/';
      let actualUrl = route?.path;
      const actualQuery = route?.query;

      // @TODO: For some weird reason, route.path sometimes just brings the following string
      if (actualUrl === '/https;') {
        return;
      }

      redirectUrl += Object.keys(urlParts)
        .filter((key) => urlParts[key] !== null)
        .map((key) => {
          if (key === 'areaUrl') {
            return `a/${urlParts[key]}`;
          }

          if (key === 'regionUrl') {
            return `r/${urlParts[key]}`;
          }

          if (key === 'districtUrl') {
            return `d/${urlParts[key]}`;
          }

          if (key === 'propertyIdUrl') {
            return `p/${urlParts[key]}`;
          }
          if (key === 'chainUrl') {
            return `${commonValues.HOSTELCHAINURL}/${urlParts[key]}`;
          }
          if (key === 'cityFilterUrl') {
            return `f/${urlParts[key]}`;
          }
          if (key === 'districtFilterUrl') {
            return `f/${urlParts[key]}`;
          }
          return urlParts[key];
        })
        .join('/');

      redirectUrl = nuxtApp.$url.addSlashOnEnd(redirectUrl)?.replace('//', '/');
      actualUrl = nuxtApp.$url.addSlashOnEnd(actualUrl)?.replace('//', '/');
      if (query && Object.keys(query).length > 0) {
        redirectUrl = nuxtApp.$url.buildQueryDecoded(redirectUrl, query);
      }
      if (actualQuery && Object.keys(actualQuery).length > 0) {
        actualUrl = nuxtApp.$url.buildQueryDecoded(actualUrl, actualQuery);
      }

      if (redirectUrl) {
        const matcher = new RegExp(`^${default404}/?$`);
        if (matcher.test(redirectUrl)) {
          throw createError({
            statusCode: 404,
            statusMessage: `Page not found: ${actualUrl}`,
          });
        }

        // @TODO: Check that this doesn't cause issues on Server VS Client side
        // if (process.server && redirectUrl !== decodeURIComponent(actualUrl)) {
        if (redirectUrl !== decodeURIComponent(actualUrl)) {
          await nuxtApp.runWithContext(() => navigateTo(redirectUrl, { redirectCode: 301 }));
        }
      }
    }
  };

  // @NOTE: hwroute/getUrlData object keys needs to equal the one used on the composable useRoutes and routeStore
  const getUrlData = (
    propertyTypeUrl = null,
    continentUrl = null,
    countryUrl = null,
    cityUrl = null,
    cityPageNumber = null,
    propertyIdUrl = null,
    propertyNameUrl = null,
    areaUrl = null,
    regionUrl = null,
    districtUrl = null,
    districtPageNumber = null,
    chainUrl = null,
    cityFilterUrl = null,
    districtFilterUrl = null,
    genericUrl = null,
  ) => {
    return {
      langUrl: getActualLangForUrl(),
      propertyTypeUrl,
      continentUrl,
      countryUrl,
      cityUrl,
      cityPageNumber,
      propertyIdUrl,
      propertyNameUrl,
      areaUrl,
      regionUrl,
      districtUrl,
      districtPageNumber,
      chainUrl,
      cityFilterUrl,
      districtFilterUrl,
      genericUrl,
    };
  };

  const cycleThroughTranscodesFilesToCheckPropertyTypeUrl = (langCode, propertyType) => {
    let transcodeSelected = null;
    let fileSelected = null;

    const transcodesFiles = {
      t_SLUGPROPERTYTYPEHOSTELS,
      t_SLUGPROPERTYTYPEHOTELS,
      t_SLUGPROPERTYTYPEBNB,
    };

    Object.keys(transcodesFiles).find((fileName) => {
      Object.keys(transcodesFiles[fileName]).find((transcode) => {
        if (propertyType === transcodesFiles[fileName][transcode]) {
          transcodeSelected = transcodesFiles[fileName][langCode];
          fileSelected = fileName;
        }
        return fileSelected;
      });
      return fileSelected;
    });

    if (fileSelected && transcodeSelected) {
      const propertySlugObj = PROPERTY_TYPES.find((properties) => properties.slug === fileSelected);
      if (propertySlugObj && propertySlugObj.key) {
        return {
          id: propertySlugObj.id,
          type: propertySlugObj.type,
          key: propertySlugObj.key,
          transcode: propertySlugObj.transcode,
          transcode_s: propertySlugObj.transcode_s,
          slug: transcodeSelected,
          limit: propertySlugObj.limit,
        };
      }
    }
  };

  const validatePropertyTypeParam = (propertyType) => {
    let actualPropertyTypeObj = PROPERTY_TYPE_DEFAULT;

    const transcodesPropertyTypes = {
      t_SLUGPROPERTYTYPEHOSTELS: t_SLUGPROPERTYTYPEHOSTELS[actualLangIsoCode],
      t_SLUGPROPERTYTYPEHOTELS: t_SLUGPROPERTYTYPEHOTELS[actualLangIsoCode],
      t_SLUGPROPERTYTYPEBNB: t_SLUGPROPERTYTYPEBNB[actualLangIsoCode],
    };

    const propertyTypeIsValid
      = Object.values(t_SLUGPROPERTYTYPEHOSTELS).includes(propertyType)
        || Object.values(t_SLUGPROPERTYTYPEHOTELS).includes(propertyType)
        || Object.values(t_SLUGPROPERTYTYPEBNB).includes(propertyType);

    propertyType = propertyTypeIsValid ? propertyType : transcodesPropertyTypes[actualPropertyTypeObj.slug];
    propertyType = nuxtApp.$url.replaceUrlCharacters(propertyType);

    const propertyResult = {
      key: default404,
      slug: default404,
    };

    let currentPropertyTypeSlug = null;
    let currentPropertyTypeKey = null;
    Object.keys(transcodesPropertyTypes).forEach((key) => {
      if (transcodesPropertyTypes[key] === propertyType) {
        currentPropertyTypeSlug = transcodesPropertyTypes[key];
        actualPropertyTypeObj = PROPERTY_TYPES.find((properties) => properties.slug === key);
        currentPropertyTypeKey = actualPropertyTypeObj && actualPropertyTypeObj.key ? actualPropertyTypeObj.key : null;
      }
    });

    if (currentPropertyTypeKey && currentPropertyTypeSlug) {
      routeStore.setPropertyType(currentPropertyTypeKey);
      routeStore.setRoute({
        langUrl: getActualLangForUrl(),
        propertyTypeUrl: currentPropertyTypeSlug,
      });

      return {
        id: actualPropertyTypeObj.id,
        type: actualPropertyTypeObj.type,
        key: currentPropertyTypeKey,
        transcode: actualPropertyTypeObj.transcode,
        transcode_s: actualPropertyTypeObj.transcode_s,
        slug: currentPropertyTypeSlug,
        limit: actualPropertyTypeObj.limit,
      };
    }

    const propertyCycleResult = cycleThroughTranscodesFilesToCheckPropertyTypeUrl(actualLangIsoCode, propertyType);
    if (propertyCycleResult && propertyCycleResult.key && propertyCycleResult.slug) {
      routeStore.setPropertyType(propertyCycleResult.key);
      routeStore.setRoute({
        langUrl: getActualLangForUrl(),
        propertyTypeUrl: propertyCycleResult.slug,
      });

      return {
        id: propertyCycleResult.id,
        type: propertyCycleResult.type,
        key: propertyCycleResult.key,
        transcode: propertyCycleResult.transcode,
        transcode_s: propertyCycleResult.transcode_s,
        slug: propertyCycleResult.slug,
        limit: propertyCycleResult.limit,
      };
    }

    return propertyResult;
  };

  const validateContinentParam = async (propertyTypeObj, continent) => {
    const { getContinentByName } = useApiContinents();

    const continentsObj = await getContinentByName(propertyTypeObj?.key, continent);

    if (!continentsObj || continentsObj?.id === null) {
      const errorDescription = `### Continent not found: ${propertyTypeObj?.key}/${continent}/`;
      throw createError({
        statusCode: 404,
        statusMessage: errorDescription,
        ...enrichedErrorResponse(errorDescription),
      });
    }

    if (continentsObj && continentsObj?.id) {
      routeStore.setRoute({
        langUrl: getActualLangForUrl(),
        propertyTypeUrl: propertyTypeObj?.slug,
        continentUrl: continentsObj?.urlFriendlyName,
      });
    }

    return continentsObj;
  };

  const validateCountryParam = async (propertyTypeObj, country) => {
    const { getCountryByName } = useApiCountries();

    const countryObj = await getCountryByName(propertyTypeObj?.key, country);

    if (!countryObj || countryObj?.id === null) {
      const errorDescription = `### Country not found: ${propertyTypeObj?.key}/${country}/`;
      throw createError({
        statusCode: 404,
        statusMessage: errorDescription,
        ...enrichedErrorResponse(errorDescription),
      });
    }

    if (countryObj && countryObj?.id) {
      routeStore.setRoute({
        langUrl: getActualLangForUrl(),
        propertyTypeUrl: propertyTypeObj?.slug,
        continentUrl: countryObj?.urlFriendlyContinent,
        countryUrl: countryObj?.urlFriendlyName,
      });
    }

    return countryObj;
  };

  const validateAreaParam = async (propertyTypeObj, country, area) => {
    const { getAreaByName } = useApiAreas();

    const areaObj = await getAreaByName(propertyTypeObj?.key, country, area);

    if (!areaObj || areaObj?.id === null) {
      const errorDescription = `### Area not found: ${propertyTypeObj?.key}/${area}/`;
      throw createError({
        statusCode: 404,
        statusMessage: errorDescription,
        ...enrichedErrorResponse(errorDescription),
      });
    }

    if (areaObj && areaObj?.id) {
      routeStore.setRoute({
        langUrl: getActualLangForUrl(),
        propertyTypeUrl: propertyTypeObj?.slug,
        continentUrl: areaObj?.urlFriendlyContinent,
        countryUrl: areaObj?.urlFriendlyCountry,
        areaUrl: areaObj?.urlFriendlyName,
      });
    }

    return areaObj;
  };

  const validateRegionParam = async (propertyTypeObj, country, region) => {
    const { getRegionByName } = useApiRegions();

    const regionObj = await getRegionByName(propertyTypeObj?.key, country, region);

    if (!regionObj || regionObj?.id === null) {
      const errorDescription = `### Region not found: ${propertyTypeObj?.key}/${region}/`;
      throw createError({
        statusCode: 404,
        statusMessage: errorDescription,
        ...enrichedErrorResponse(errorDescription),
      });
    }

    if (regionObj && regionObj?.id) {
      routeStore.setRoute({
        langUrl: getActualLangForUrl(),
        propertyTypeUrl: propertyTypeObj?.slug,
        continentUrl: regionObj?.urlFriendlyContinent,
        countryUrl: regionObj?.urlFriendlyCountry,
        regionUrl: regionObj?.urlFriendlyName,
      });
    }

    return regionObj;
  };

  const validateCityParam = async (propertyTypeObj, country, city) => {
    const { getCityByCityNameAndCountryName } = useApiCities();

    const cityObj = await getCityByCityNameAndCountryName(propertyTypeObj?.key, country, city);

    if (!cityObj || cityObj?.id === null) {
      const errorDescription = `### City not found: ${propertyTypeObj?.key}/${country}/${city}/`;
      throw createError({
        statusCode: 404,
        statusMessage: errorDescription,
        ...enrichedErrorResponse(errorDescription),
      });
    }

    if (cityObj && cityObj?.id) {
      routeStore.setRoute({
        langUrl: getActualLangForUrl(),
        propertyTypeUrl: propertyTypeObj?.slug,
        continentUrl: cityObj?.urlFriendlyContinent,
        countryUrl: cityObj?.urlFriendlyCountry,
        cityUrl: cityObj?.urlFriendlyName,
      });
    }

    return cityObj;
  };

  const validateDistrictParam = async (propertyTypeObj, country, city, district, pageNumber) => {
    const { getDistrictByCityNameAndCountryName } = useApiDistricts();

    const districtObj = await getDistrictByCityNameAndCountryName(
      propertyTypeObj?.key,
      country,
      city,
      district,
      pageNumber,
    );

    if (!districtObj || !districtObj?.id) {
      const errorDescription = `### District not found: ${propertyTypeObj?.key}/${country}/${city}/${district}/`;
      throw createError({
        statusCode: 404,
        statusMessage: errorDescription,
        ...enrichedErrorResponse(errorDescription),
      });
    }

    if (districtObj && districtObj?.id) {
      routeStore.setRoute({
        langUrl: getActualLangForUrl(),
        propertyTypeUrl: propertyTypeObj?.slug,
        continentUrl: districtObj?.urlFriendlyContinent,
        countryUrl: districtObj?.urlFriendlyCountry,
        cityUrl: districtObj?.urlFriendlyCity,
        districtUrl: districtObj?.urlFriendlyName,
        districtPageNumber: pageNumber,
      });
    }

    return districtObj;
  };

  const validatePropertyParam = async (propertyTypeObj, propertyId) => {
    const { getPropertyByPropertyId } = useApiProperties();

    let propertyObj = {};

    try {
      propertyObj = await getPropertyByPropertyId(propertyId);
    } catch (err) {
      if (err) {
        console.error(err);
        const homePageUrl = useUrl.getStaticBaseURL();
        navigateTo(homePageUrl, { external: true });
      }
    }

    if (!propertyObj || propertyObj?.id === null) {
      const description = `No property ${propertyId} found`;
      // @TODO: Add proper error logging for GCR
      // throw { error: this.enrichedErrorResponse(description, req) };
      console.error(description);
    }

    routeStore.setRoute({
      langUrl: getActualLangForUrl(),
      propertyTypeUrl: propertyTypeObj?.slug,
      propertyIdUrl: propertyObj?.id,
      propertyNameUrl: propertyObj?.urlFriendlyName,
    });

    return propertyObj;
  };

  const validateHostelChainParam = async (hostelChainUrl) => {
    const { getHostelChainByName } = useApiHostelBrands();

    const hostelChainObj = await getHostelChainByName(hostelChainUrl);

    if (!hostelChainObj || !hostelChainObj?.id) {
      const errorDescription = `### Hostel Brand not found: ${hostelChainUrl}`;

      throw createError({
        statusCode: 404,
        statusMessage: errorDescription,
        ...enrichedErrorResponse(errorDescription),
      });
    }

    routeStore.setRoute({
      langUrl: getActualLangForUrl(),
      chainUrl: hostelChainObj?.urlFriendlyName,
    });

    return hostelChainObj;
  };

  return {
    useRouteManager: {
      getActualLangForUrl,
      getUrlData,
      redirectRoute,
      enrichedErrorResponse,
      validatePropertyTypeParam,
      validateContinentParam,
      validateCountryParam,
      validateAreaParam,
      validateRegionParam,
      validateCityParam,
      validateDistrictParam,
      validatePropertyParam,
      validateHostelChainParam,
    },
  };
}
