import { useCurrencyStore } from '@/stores/currency';

export function useApiHostelBrands() {
  const {
    $api,
    $i18n,
    $url,
  } = useNuxtApp();
  const { locale } = $i18n;

  const currencyStore = useCurrencyStore();

  const userCurrency = currencyStore.getCurrentCurrency;

  const defaultQuery = { currency: userCurrency?.code };

  const defaultHeaders = { 'Accept-Language': locale?.value };

  const defaultParams = {};

  const getHostelChainByName = async (hostelChain) => {
    const hostelChainUrl = $url.replaceUrlCharacters(hostelChain);

    const callKey = `getHostelChainByName${hostelChainUrl}${locale?.value}${userCurrency?.code}`;
    const endpoint = `hostel-group/${hostelChainUrl}/`;

    const result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));
    const hostelChainData = result?.value?.data;

    return {
      id: hostelChainData?.id,
      name: hostelChainData?.name,
      urlFriendlyName: hostelChainUrl,
      image: hostelChainData?.image,
      numberOfProperties: hostelChainData?.propertyCount,
      numberOfCountries: hostelChainData?.countries?.length,
      tracking: hostelChainData?.tracking,
      countries: hostelChainData?.countries,
    };
  };

  return { getHostelChainByName };
}
